import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./view/home/home";
import About from "./view/about/about";
import Services from "./view/services/services";
import Budget from "./view/budget/budget";
import { EmailSentProvider } from "./context/EmailSentContext";
import EmailSentModal from "./components/EmailSentModal";
import EmailSuccess from "./view/emailSuccess/emailSuccess";

function RouteApp() {
  return (
    <EmailSentProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" name="Home" element={<Home />} />
          <Route path="/about" name="About" element={<About />} />
          <Route path="/services" name="Services" element={<Services />} />
          <Route path="/budget" name="Budget" element={<Budget />} />
          <Route path="/emailSuccess" name="EmailSuccess" element={<EmailSuccess />} />
        </Routes>
      </BrowserRouter>
      <EmailSentModal />
    </EmailSentProvider>
  );
}

export default RouteApp;
