import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

function AboutOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className="moreAbout">
        <div className={`moreTitle ${isVisible ? "visible" : ""}`}>
          <h1>Descubra mais sobre nós.</h1>
        </div>
        <div className="moreContent">
          <div className={`start ${isVisible ? "visible" : ""}`}>
            <h1>Início</h1>
            <p>
              Tudo começou em 2013, quando demos os primeiros passos como
              PortoVig, oferecendo serviço de Monitoramento Eletrônico. Naquela
              época, éramos movidos por um compromisso inabalável com a
              segurança e pela vontade incessante de aprimorar nossos serviços.
            </p>
          </div>
          <div className={`evolution ${isVisible ? "visible" : ""}`}>
            <h1>Evolução</h1>
            <p>
              À medida que o tempo avançava, nós também evoluíamos. Expandimos
              nossas operações para além do monitoramento eletrônico, abraçando
              serviços de Segurança Patrimonial e Facilities. Essa jornada de
              crescimento foi impulsionada pelo desejo de proporcionar o melhor
              em segurança, mantendo sempre nosso foco na qualidade e no
              compromisso com nossos clientes.
            </p>
          </div>
          <div className={`legacy ${isVisible ? "visible" : ""}`}>
            <h1>Nosso Legado</h1>
            <p>
              Hoje, nos encontramos firmemente estabelecidos como uma referência
              no mercado de segurança. No entanto, nossa jornada está longe de
              acabar. Nosso legado é uma narrativa de dedicação, excelência e
              inovação - uma história que continua a se desenrolar à medida que
              avançamos para o futuro. Estamos prontos para enfrentar os
              desafios que surgirem no caminho e deixar um impacto positivo
              duradouro em nossa indústria e comunidade.
            </p>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default AboutOne;
