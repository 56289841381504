import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

const HomeAbout = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <>
      <VisibilitySensor onChange={onChange} partialVisibility>
        <div className="sectionOne">
          <h1>
            Veja como nossos serviços podem atender às suas <br /> necessidades
            específicas
          </h1>
          <div className="container-section-one">
            <div className={`contentOne ${isVisible ? "animate" : ""}`}>
              <div className="texto1">
                <h2>Profissionais altamente qualificados</h2>
                <p>
                  Cada membro de nossa equipe é cuidadosamente selecionado,
                  submetido a uma análise rigorosa de antecedentes, avaliação de
                  histórico profissional e treinamento especializado em
                  segurança. Investimos em um processo abrangente de capacitação
                  para todos os novos integrantes, garantindo que estejam
                  totalmente preparados para suas responsabilidades
                  operacionais.
                </p>
              </div>
            </div>
            <div
              className={`contentTwo ${isVisible ? "animate" : ""}`}
              style={{
                transform: `translateY(${isVisible ? "0" : "-100%"})`,
                opacity: isVisible ? 1 : 0,
              }}
            >
              <div className="texto2">
                <h2>Tecnologia avançada e inovação constante</h2>
                <p>
                  Empregamos tecnologia de ponta para oferecer uma portaria
                  autônoma, uma solução moderna e automatizada para o controle
                  de acesso em edifícios residenciais. Além disso, nossa
                  infraestrutura inclui câmeras de vigilância, alarmes e
                  sensores de última geração, assegurando uma segurança
                  abrangente e em tempo real para nossos clientes.
                </p>
              </div>
            </div>
            <div className={`contentThree ${isVisible ? "animate" : ""}`}>
              <div className="texto3">
                <h2>Resposta rápida e eficiente</h2>
                <p>
                  Nosso compromisso é garantir um tempo de resposta rápido e
                  eficiente por parte de nossa equipe, que está sempre atenta
                  para esclarecer suas dúvidas e responder às suas perguntas com
                  prontidão.
                </p>
              </div>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </>
  );
};

export default HomeAbout;
