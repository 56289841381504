import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import ImageHero1 from "../../../assets/img/home/hero1.webp";
import ImageHero2 from "../../../assets/img/home/hero2.webp";
import ImageHero3 from "../../../assets/img/home/hero3.webp";

const HomeHero = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <>
      <div className="hero">
        <div className="content-hero">
          <div className="title">
            <VisibilitySensor onChange={onChange} partialVisibility>
              <h1 className={`${isVisible ? "visible" : ""}`}>
                <span>PortoVig </span>segurança com <br /> excelência
              </h1>
            </VisibilitySensor>
            <VisibilitySensor onChange={onChange} partialVisibility>
              <h4 className={`${isVisible ? "visible" : ""}`}>
                Monitoramento Eletrônico, Segurança <br /> Patrimonial e
                Facilities - Compromisso com a <br /> qualidade para atender
                você.
              </h4>
            </VisibilitySensor>
          </div>
          <VisibilitySensor onChange={onChange} partialVisibility>
            <div className={`content2 ${isVisible ? "visible" : ""}`}>
              <div className="img-hero">
                <img className="logo" src={ImageHero1} alt="" />
                <img className="img1" src={ImageHero2} alt="" />
                <img className="img2" src={ImageHero3} alt="" />
                <div className="item1 item">
                  <h1>Monitoramento de alarmes</h1>
                </div>
                <div className="item2 item">
                  <h1>Controle de acesso</h1>
                </div>
                <div className="item3 item">
                  <h1>Vigilância</h1>
                </div>
                <div className="item4 item">
                  <h1>Bombeiro civil</h1>
                </div>
                <div className="item5 item">
                  <h1>Auxiliar de serviços gerais</h1>
                </div>
                <div className="item6 item">
                  <h1>Porteiro</h1>
                </div>
                <div className="item7 item">
                  <h1>Zelador</h1>
                </div>
                <div className="item8 item">
                  <h1>Jardineiro</h1>
                </div>
                <div className="item9 item">
                  <h1>Recepcionista</h1>
                </div>
              </div>
            </div>
          </VisibilitySensor>
        </div>
      </div>
    </>
  );
};

export default HomeHero;
