import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import "swiper/css";
import "swiper/css/pagination";

import img1 from "../../../assets/img/about/img-gallery-two.webp";
import img2 from "../../../assets/img/about/uniforme3.webp";
import img4 from "../../../assets/img/about/uniforme4.webp";
import img5 from "../../../assets/img/about/img-gallery-four.webp";
import img6 from "../../../assets/img/about/img-gallery-three.webp";
import img7 from "../../../assets/img/about/img-gallery-six.webp";
import img9 from "../../../assets/img/about/img-gallery-eight.webp";
import img10 from "../../../assets/img/about/img-gallery-nine.webp";
import img11 from "../../../assets/img/about/img-gallery-eleven.webp";
import img12 from "../../../assets/img/about/img-gallery-twelve.webp";
import img13 from "../../../assets/img/about/img-gallery-fourteen.jpg";

const data = [
  {
    id: 1,
    imageUrl: img1,
  },
  {
    id: 2,
    imageUrl: img2,
  },
  {
    id: 3,
    imageUrl: img4,
  },
  {
    id: 4,
    imageUrl: img5,
  },
  {
    id: 5,
    imageUrl: img6,
  },
  {
    id: 6,
    imageUrl: img7,
  },
  {
    id: 7,
    imageUrl: img9,
  },
  {
    id: 8,
    imageUrl: img10,
  },
  {
    id: 9,
    imageUrl: img11,
  },
  {
    id: 10,
    imageUrl: img12,
  },
  {
    id: 11,
    imageUrl: img13,
  },
];

function AboutFour() {
  return (
    <div className="photoGallery">
      <div className="photoGalleryTitle">
        <h1>Galeria de fotos</h1>
      </div>
      <Swiper
        modules={[Pagination]}
        pagination={{
          clickable: true,
        }}
        className="mySwiper"
        loop
        grabCursor={true}
        slidesPerView={1}
        spaceBetween={0}
      >
        {data.map((item) => (
          <SwiperSlide key={item.id}>
            <div className="card">
              <img src={item.imageUrl} alt="" />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default AboutFour;
