import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import Brasil from "../../../assets/img/home/map1.webp";
import icon from "../../../assets/icon/map/iconMap1.png";
import icon2 from "../../../assets/icon/map/iconMap2.png";
import iconWhite from "../../../assets/icon/map/iconMap1.png";

function HomeMap() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className="map">
        <div className="mapContent">
          <img
            className={`itemMap ${isVisible ? "visible" : ""}`}
            src={icon2}
            alt=""
          />
          <img
            className={`brasil ${isVisible ? "visible" : ""}`}
            src={Brasil}
            alt=""
          />
          <div className={`point point1 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Joinville</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point2 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Araquari</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point3 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Barra Velha</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point4 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Penha</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point5 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Piçarras</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point6 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Navegantes</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point7 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Itajaí</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point8 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Ilhota</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point9 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Gaspar</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point10 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Blumenau</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point11 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Balneário Camboriú</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point12 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Camboriú</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point13 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Itapema</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point14 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Brusque</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point15 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Nova Trento</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point16 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>São José</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point17 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Florianópolis</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
          <div className={`point point18 ${isVisible ? "visible" : ""}`}>
            <div className="card">
              <h1>Mafra</h1>
            </div>
            <img className="local" src={iconWhite} width={"20px"} alt="" />
          </div>
        </div>
        <div className="mapTitle">
          <img className={`${isVisible ? "visible" : ""}`} src={icon} alt="" />
          <div className={`mapTitle2 ${isVisible ? "visible" : ""}`}>
            <h1>Onde estamos localizados ?</h1>
            <h2>Rua José Gall, 20, Itajaí, SC - 88.307-100</h2>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default HomeMap;
