import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import servicesImg3 from "../../../assets/img/services/img-service.png";
import servicesImg2 from "../../../assets/img/services/servicesThree2.png";
import servicesImg1 from "../../../assets/img/services/servicesThree3.png";

function ServicesThree() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className="servicesThree">
        <div className="servicesThreeTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Segurança <span>eletrônica</span>
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Integramos equipamentos para garantir segurança de pessoas e bens.
          </p>
        </div>
        <div className="servicesThreeContent">
          <div className="contentText">
            <div className={`content1 ${isVisible ? "visible" : ""}`}>
              <h1>Recursos Essenciais</h1>
              <h6>
                Na área de segurança eletrônica, contamos com recursos
                fundamentais para proteger ambientes diversos:
              </h6>
              <strong>- Controle de Acesso</strong> <br></br>
              <strong>- Sistema de Alarme</strong> <br></br>
              <strong>- Circuito Fechado de Televisão (CFTV)</strong> <br></br>
              <strong>- Central de Monitoramento</strong> <br></br>
            </div>
            <div className={`content2 ${isVisible ? "visible" : ""}`}>
              <p>
                O controle de acesso regula quem pode entrar em determinadas
                áreas, enquanto o sistema de alarme alerta sobre possíveis
                invasões. O CFTV monitora e grava imagens para análise
                posterior, enquanto a central de monitoramento recebe e gerencia
                informações dos sistemas instalados. Essas tecnologias são
                fundamentais para garantir a segurança de espaços diversos,
                proporcionando tranquilidade aos usuários.
              </p>
            </div>
          </div>
          <div className={`contentImg ${isVisible ? "visible" : ""}`}>
            <img className="img1" src={servicesImg1} width={"600px"} alt="" />
            <img className="img2" src={servicesImg2} width={"600px"} alt="" />
            <img className="img3" src={servicesImg3} width={"600px"} alt="" />
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default ServicesThree;
