import React from "react";

import poster from '../../../assets/img/about/uniforme1.webp';

function AboutSix(){
    return(
        <div className="aboutSix">
            <div className="aboutSixTitle">
                <h1>Protegendo seu Mundo:<span> A Experiência Segura com a PortoVig</span></h1>
                <p>Inovação em Monitoramento de Alarmes para Garantir a Tranquilidade que Você Merece</p>
            </div>
            <div className="aboutSixVideo">
                <video
                    src="https://singlec.com.br/imagens-site/singlec_sobre.mp4"
                    controls
                    controlsList="nodownload"
                    poster={poster}
                >   
                </video>
            </div>
        </div>
    )
}
export default AboutSix;