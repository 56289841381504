import React, { useState } from "react";
import VisibilitySensor from 'react-visibility-sensor';

function ServicesTwo(){

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return(
    <div className="servicesTwo">
      <h1 className={`${isVisible ? 'visible' : ''}`}>Serviços que transformam</h1>
      <VisibilitySensor onChange={onChange} partialVisibility>
      <div className="servicesTwoContent">
        <div className={`servicesTwoCard stc1 ${isVisible ? 'visible' : ''}`}>
          <h1>Facilities de vanguarda</h1>
          <p>Não apenas protegemos, mas também facilitamos, criando ambientes mais eficientes e confortáveis</p>
        </div>
        <div className={`servicesTwoCard stc2 ${isVisible ? 'visible' : ''}`}>
          <h1>Segurança com estilo</h1>
          <p>Nossa equipe, além de altamente treinada, traz uma abordagem moderna e amigável à segurança patrimonial</p>
        </div>
        <div className={`servicesTwoCard stc3 ${isVisible ? 'visible' : ''}`}>
          <h1>Monitoramento 24/7</h1>
          <p>Sua segurança não tira férias, e nós também não. Monitoramos seus espaços constantemente para oferecer proteção ininterrupta.</p>
        </div>
      </div>
      </VisibilitySensor>
    </div>
  )
}
export default ServicesTwo;