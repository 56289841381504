import axios from "axios";

const config = {
  baseURL: "https://emailsender.singlec.com.br/api/",
};

const axiosConfig = axios.create(config);

axiosConfig.defaults.headers["Content-Type"] = "application/json";

export const api = axiosConfig;
