import { createContext, useContext, useEffect, useState } from "react";

const EmailSentContext = createContext({
    setShouldShowModal: () => {},
    shouldShowModal: false
})

export const EmailSentProvider = ({children}) => {

    const [shouldShowModal, setShouldShowModal] = useState(false)

    useEffect(() => {

        if(shouldShowModal){
            setTimeout(() => setShouldShowModal(false), 6000)
        }

    }, [shouldShowModal])

    return (
        <EmailSentContext.Provider value={{setShouldShowModal, shouldShowModal}}>
            {children}
        </EmailSentContext.Provider>
    )
}

export const useEmailSent = () => useContext(EmailSentContext)