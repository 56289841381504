import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import federal from "../../../assets/img/home/certifications1.webp";
import exercito from "../../../assets/img/home/certifications2.webp";
import civil from "../../../assets/img/home/certifications3.webp";

function HomeCertifications() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className="certifications">
        <h1 className={`certificationsTitle ${isVisible ? "visible" : ""}`}>
          Nossas certificações
        </h1>
        <div className="cards">
          <div className={`card federal ${isVisible ? "visible" : ""}`}>
            <img src={federal} alt="" />
            <p>
              O Certificado da Polícia Federal permite que empresas de segurança
              possam atuar com produtos controlados, atestando a capacidade da
              empresa na guarda desses materiais, contratação de profissionais
              qualificados (vigilantes), além de fiscalizar anualmente a
              empresa, atestando essa capacidade.
            </p>
          </div>
          <div className={`card exercito ${isVisible ? "visible" : ""}`}>
            <img src={exercito} alt="" />
            <p>
              O Certificado do Exército permite que empresas de vigilância
              possam comprar produtos de acesso restrito, como armamentos, balas
              e coletes balísticos.
            </p>
          </div>
          <div className={`card civil ${isVisible ? "visible" : ""}`}>
            <img src={civil} alt="" />
            <p>
              O Certificado da Polícia Civil permite que empresas de vigilância
              operem dentro da legalidade. Isso garante que as empresas atuem de
              acordo com as regulamentações e padrões de segurança
              estabelecidos, podendo assim operar em grandes eventos.
            </p>
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default HomeCertifications;
