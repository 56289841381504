import React, { useState } from "react";
import VisibilitySensor from 'react-visibility-sensor';
import axios from "axios";

function HomeFeed() {

  const [feedList, setFeedList] = React.useState([]);
  const [itemId0, setItemId0] = useState(null);
  const [itemId1, setItemId1] = useState(null);
  const [itemId2, setItemId2] = useState(null);
  const [itemId3, setItemId3] = useState(null);
  const [itemId4, setItemId4] = useState(null);

  async function getInstaFeed() {
    const token = process.env.REACT_APP_INSTA_TOKEN;
    const fields = "media_url,media_type,permalink,caption, timestamp";
    const url = `https://graph.instagram.com/me/media?access_token=${token}&fields=${fields}`;

    const { data } = await axios.get(url);
    console.log(data);
    const images = data.data.filter(
      (item) =>
        item.media_type === "IMAGE" ||
        item.media_type === "CAROUSEL_ALBUM" ||
        item.media_type === "VIDEO"
    );
    const limitedImages = images.slice(0, 5); // Define o número máximo de imagens a serem exibidas (neste exemplo, 8)
    setFeedList(limitedImages);
    setItemId0(limitedImages[0].id);
    setItemId1(limitedImages[1].id);
    setItemId2(limitedImages[2].id);
    setItemId3(limitedImages[3].id);
    setItemId4(limitedImages[4].id);
  }

  React.useEffect(() => {
    getInstaFeed();
  }, []);

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
    <div className="instaFeed">
      <h1 className={`${isVisible ? 'visible' : ''}`}>Explore nossas redes sociais para ficar por</h1>
      <h2 className={`${isVisible ? 'visible' : ''}`}>dentro das últimas novidades</h2>
      <div className={`cards ${isVisible ? 'visible' : ''}`}>
        <div className="card1">
          {feedList.map((item) => {
            if (item.id === itemId0) {
              return (
                <a
                  href={item.permalink}
                  target="_blank"
                  rel="noreferrer"
                  key={item.id}
                >
                  {item.media_type === "IMAGE" ||
                  item.media_type === "CAROUSEL_ALBUM" ? (
                    <div className="card-media">
                      <img src={item.media_url} rel="preload" alt="" />
                      <div className="media-text">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="=card-media">
                      <video
                        class="clip"
                        id="media-video"
                        type="video/mp4"r
                        rel="preload"
                        loop
                        Muted
                        controls
                    >
                        <source src={item.media_url} />
                      </video>
                      <div className="media-text text-video">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  )}
                </a>
              );
            }
            return null;
          })}
        </div>
        <div className="card2">
          {feedList.map((item) => {
            if (item.id === itemId1) {
              return (
                <a
                  href={item.permalink}
                  target="_blank"
                  rel="noreferrer"
                  key={item.id}
                >
                  {item.media_type === "IMAGE" ||
                  item.media_type === "CAROUSEL_ALBUM" ? (
                    <div className="card-media">
                      <img src={item.media_url} alt="" />
                      <div className="media-text">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="=card-media">
                      <video
                        class="clip"
                        id="media-video"
                        type="video/mp4"
                        loop
                        Muted
                        controls
                      >
                        <source src={item.media_url} />
                      </video>
                      <div className="media-text text-video">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  )}
                </a>
              );
            }
            return null;
          })}
        </div>
        <div className="card3">
          {feedList.map((item) => {
            if (item.id === itemId2) {
              return (
                <a
                  href={item.permalink}
                  target="_blank"
                  rel="noreferrer"
                  key={item.id}
                >
                  {item.media_type === "IMAGE" ||
                  item.media_type === "CAROUSEL_ALBUM" ? (
                    <div className="card-media">
                      <img src={item.media_url} alt="" />
                      <div className="media-text">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="=card-media">
                      <video
                        class="clip"
                        id="media-video"
                        type="video/mp4"
                        loop
                        Muted
                        controls
                      >
                        <source src={item.media_url} />
                      </video>
                      <div className="media-text text-video">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  )}
                </a>
              );
            }
            return null;
          })}
        </div>
        <div className="card4">
          {feedList.map((item) => {
            if (item.id === itemId3) {
              return (
                <a
                  href={item.permalink}
                  target="_blank"
                  rel="noreferrer"
                  key={item.id}
                >
                  {item.media_type === "IMAGE" ||
                  item.media_type === "CAROUSEL_ALBUM" ? (
                    <div className="card-media">
                      <img src={item.media_url} alt="" />
                      <div className="media-text">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="=card-media">
                      <video
                        class="clip"
                        id="media-video"
                        type="video/mp4"
                        loop
                        Muted
                        controls
                      >
                        <source src={item.media_url} />
                      </video>
                      <div className="media-text text-video">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  )}
                </a>
              );
            }
            return null;
          })}
        </div>
        <div className="card5">
          {feedList.map((item) => {
            if (item.id === itemId4) {
              return (
                <a
                  href={item.permalink}
                  target="_blank"
                  rel="noreferrer"
                  key={item.id}
                >
                  {item.media_type === "IMAGE" ||
                  item.media_type === "CAROUSEL_ALBUM" ? (
                    <div className="card-media">
                      <img src={item.media_url} alt="" />
                      <div className="media-text">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  ) : (
                    <div className="=card-media">
                      <video
                        class="clip"
                        id="media-video"
                        type="video/mp4"
                        loop
                        Muted
                        controls
                      >
                        <source src={item.media_url} />
                      </video>
                      <div className="media-text text-video">
                        <p>{item.caption}</p>
                      </div>
                    </div>
                  )}
                </a>
              );
            }
            return null;
          })}
        </div>
      </div>
    </div>
    </VisibilitySensor>
  );
}

export default HomeFeed;
