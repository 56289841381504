import SocialMedia from "../../components/SocialMedia";
import NavBar from '../../components/NavBar';
import Footer from '../../components/Footer';
import BudgetOne from '../budget/components/BudgetOne';

function Budget() {
  return (
    <div className="budget">
      <SocialMedia />
      <NavBar></NavBar>  
      <BudgetOne></BudgetOne>
      <Footer></Footer>
    </div>  
  );
}

export default Budget;
