import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import servicesImg3 from "../../../assets/img/services/servicesFour4.png";
import servicesImg2 from "../../../assets/img/services/servicesFour2.png";
import servicesImg1 from "../../../assets/img/services/servicesFour3.png";

function ServicesFour() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className="servicesFour">
        <div className="servicesFourTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Segurança <span>patrimonial</span>
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            São ações para proteger o patrimônio de organizações, prevenindo
            danos e interferências.
          </p>
        </div>
        <div className="servicesFourContent">
          <div className={`contentImg ${isVisible ? "visible" : ""}`}>
            <img className="img1" src={servicesImg1} width={"600px"} alt="" />
            <img className="img2" src={servicesImg2} width={"600px"} alt="" />
            <img className="img3" src={servicesImg3} width={"600px"} alt="" />
          </div>
          <div className="contentText">
            <div className={`content1 ${isVisible ? "visible" : ""}`}>
              <p>
                <strong>Proteger o patrimônio da organização: </strong>
                Emprega técnicas e recursos adequados para proteger os ativos da
                empresa conforme o risco identificado.
              </p>
              <p>
                <strong>Adotar medidas preventivas e proativas: </strong>
                Busca reduzir as ocorrências de risco por meio de ações
                antecipadas e medidas preventivas.
              </p>
              <p>
                <strong>Controlar e administrar acessos: </strong>
                Gerencia quem pode entrar e sair de áreas específicas,
                garantindo a segurança das instalações.
              </p>
              <p>
                <strong>Detectar e identificar violações: </strong>
                Monitora e identifica qualquer violação de normas, procedimentos
                ou ocorrências de delitos e sinistros.
              </p>
              <p>
                <strong>Responder de forma coordenada: </strong>
                Age de maneira coordenada e padronizada para responder às
                ameaças, mantendo a segurança dos bens da organização.
              </p>
            </div>
            {/*             <div className={`content2 ${isVisible ? "visible" : ""}`}>
              <h1></h1>
              <p></p>
            </div> */}
          </div>
        </div>
      </div>
    </VisibilitySensor>
  );
}
export default ServicesFour;
