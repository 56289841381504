import React from "react";  
import fundo from "../../assets/img/about/fundo.webp";
import fundo2 from "../../assets/img/about/fundo2.webp";
import img from "../../assets/img/emailSuccess/img2.webp";
import { Link } from "react-router-dom";

function EmailSuccess(){
    return(
        <div className="emailSuccess">
            <div className="text">
                <h1>Obrigada!<br />Mensagem enviada com sucesso.</h1>
                <div className="underline"></div>
                <Link to="/home">
                    Voltar ao site
                </Link>
            </div>
            <img className="fundo" src={fundo} alt="" />
            <img className="fundo2" src={fundo2} alt="" />
        </div>
    )
}
export default EmailSuccess;