import React from "react";
import phone from "../../../assets/img/about/phone.webp";
/* import video from "../../../assets/img/about/video.mp4"; */
import poster from "../../../assets/img/about/poster.webp";
import fundo from "../../../assets/img/about/fundo.webp";
import fundo2 from "../../../assets/img/about/fundo2.webp";

function AboutSeven() {
  return (
    <div className="aboutSeven">
      <img className="bg1" src={fundo} alt="" />
      <img className="bg2" src={fundo2} alt="" />
      <div className="phone">
        <img src={phone} alt="" width={"300px"} />
        <video
          poster={poster}
          src={"https://portovig.com.br/videos-publicos/about.mp4"}
          width={"300px"}
          preload={"auto"}
          controls
        ></video>
        <img className="bg3" src={fundo} alt="" />
      </div>
      <div className="text">
        <h1>
          Conheça um pouco sobre nós - <br /> Assista o vídeo ao lado
        </h1>
        <p>
          Na PortoVig, prezamos por oferecer um serviço de qualidade e
          segurança, refletindo o compromisso que temos com cada um de nossos
          clientes. Com mais de 10 anos no mercado, estamos sempre evoluindo
          para melhor atendê-los.
        </p>
      </div>
    </div>
  );
}
export default AboutSeven;
