import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import major from '../assets/icon/midias/conectar.png';
import Facebook from "../assets/icon/midias/facebook.png";
import Instagram from "../assets/icon/midias/instagram.png";
import Whatsapp from "../assets/icon/midias/whatsapp.png";

function SocialMedia() {

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <div>
      <VisibilitySensor onChange={onChange} partialVisibility>
      <div className={`social-media ${isVisible ? 'visible' : ''}`}>
        <div className="items">
          <div className="link">
            <img src={major} width={'50px'} alt="" />
          </div>
          <div className="item whats">
            <a href="https://api.whatsapp.com/send?phone=5547997504443&text=Ol%C3%A1" target="_blank" rel="noreferrer">
              <img src={Whatsapp} alt="" />
            </a>
          </div>
          <div className="item insta">
            <a href="https://www.instagram.com/portovig/" target="_blank" rel="noreferrer">
              <img src={Instagram} alt="" />
            </a>
          </div>
          <div className="item face">
            <a href="https://www.facebook.com/PortovigSeguranca/" target="_blank" rel="noreferrer">
              <img src={Facebook} alt="" />
            </a>
          </div>
        </div>
      </div>
      </VisibilitySensor>
    </div>
  );
}

export default SocialMedia;
