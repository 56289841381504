import React, { useState } from "react";
import { CFooter } from "@coreui/react-pro";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import Facebook from "../assets/icon/midias/facebook.svg";
import Instagram from "../assets/icon/midias/instagram.svg";
import whatssapp from "../assets/icon/midias/whatsapp.svg";
import Logo from "../assets/img/logo.png";

const Footer = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <CFooter className="footer">
        <div className="footer-background">
          <div className="links">
            <Link className={`link ${isVisible ? "visible" : ""}`} to="#">
              Início
            </Link>
            <Link
              className={`link ${isVisible ? "visible" : ""}`}
              to="/about"
            >
              Sobre
            </Link>
            <Link
              className={`link ${isVisible ? "visible" : ""}`}
              to="/services"
            >
              Serviços
            </Link>
            <img
              className={`link ${isVisible ? "visible" : ""}`}
              src={Logo}
              alt="Logo"
              width={150}
            />
            <Link
              className={`link ${isVisible ? "visible" : ""}`}
              to="/budget"
            >
              Orçamento
            </Link>
            <Link className={`link ${isVisible ? "visible" : ""}`} to="https://portovig545.n1sistemas.com.br/AreaCliente/">
              Àrea do cliente
            </Link>
          </div>
          <div className={`line ${isVisible ? "visible" : ""}`}></div>
          <div className={`sociais ${isVisible ? "visible" : ""}`}>
            <a
              href="https://www.facebook.com/PortovigSeguranca/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Facebook} className="social-icon" alt="" width={30} />
            </a>
            <a
              href="https://www.instagram.com/portovig/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={Instagram} className="social-icon" alt="" width={30} />
            </a>
            <a
              href="https://api.whatsapp.com/send?phone=5547997504443&text=Ol%C3%A1"
              target="_blank"
              rel="noreferrer"
            >
              <img src={whatssapp} className="social-icon" alt="" width={30} />
            </a>
          </div>
          <div className={`final ${isVisible ? "visible" : ""}`}>
            <p>© 2024 - PortoVig - Todos os direitos reservados</p>
            <p>
              A product developed by {""}
              <a
                href="https://singlec.com.br/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Singlec
              </a>
            </p>
          </div>
        </div>
      </CFooter>
    </VisibilitySensor>
  );
};

export default Footer;
