import React, { useState, useEffect } from "react";
import {
  CNavbar,
  CNavbarBrand,
  CContainer,
  CNavbarToggler,
  COffcanvas,
  COffcanvasHeader,
  COffcanvasTitle,
  CCloseButton,
  COffcanvasBody,
  CNavbarNav,
  CNavItem,
  CButton
} from "@coreui/react-pro";
import VisibilitySensor from "react-visibility-sensor";
import { NavLink, Link } from "react-router-dom";
import Logo from "../assets/img/logo.png";

const NavBar = () => {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const [visible, setVisible] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 0;
      setScrolled(isScrolled);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <CNavbar
        colorScheme={scrolled ? "dark" : "light"}
        className={`px-5 ${scrolled ? "scrolled" : ""}`}
        expand="xl"
      >
        <div className="nav-container">
          <CNavbarBrand className={`navLogo ${isVisible ? "visible" : ""}`}>
            <Link to="/home">
              <img src={Logo} alt="logo" width={220} />
            </Link>
          </CNavbarBrand>
          <CContainer fluid>
            <CNavbarToggler
              aria-controls="offcanvasNavbar2"
              aria-label="Toggle navigation"
              onClick={() => setVisible(!visible)}
            />
            <COffcanvas
              id="offcanvasNavbar2"
              placement="end"
              portal={false}
              visible={visible}
              onHide={() => setVisible(false)}
            >
              <COffcanvasHeader>
                <COffcanvasTitle></COffcanvasTitle>
                <CCloseButton
                  className="text-reset"
                  onClick={() => setVisible(false)}
                />
              </COffcanvasHeader>
              <COffcanvasBody>
                <div className="flex-content-nav">
                  <CNavbarNav>
                    <CNavItem
                      className={`homeLink ${isVisible ? "visible" : ""}`}
                    >
                      <NavLink to="/home" active>
                        Início
                      </NavLink>
                    </CNavItem>
                    <CNavItem
                      className={`aboutLink ${isVisible ? "visible" : ""}`}
                    >
                      <NavLink to="/about" active>
                        Sobre
                      </NavLink>
                    </CNavItem>
                    <CNavItem
                      className={`servicesLink ${isVisible ? "visible" : ""}`}
                    >
                      <NavLink to="/services" active>
                        Serviços
                      </NavLink>
                    </CNavItem>
                  </CNavbarNav>
                  <CNavItem className="flex-button-nav">
                    <NavLink
                      to="/budget"
                      className={`px-2 budgetLink ${
                        isVisible ? "visible" : ""
                      }`}
                    >
                      <CButton type="button" color="danger" className="me-2">
                        Orçamento
                      </CButton>
                    </NavLink>
                    <NavLink
                      to="https://portovig545.n1sistemas.com.br/AreaCliente/"
                      className={`px-2 clientLink ${
                        isVisible ? "visible" : ""
                      }`}
                    >
                      <CButton
                        type="button"
                        color="danger"
                        variant="outline"
                        className="me-2 btnClient"
                      >
                        Àrea do cliente
                      </CButton>
                    </NavLink>
                  </CNavItem>
                </div>
              </COffcanvasBody>
            </COffcanvas>
          </CContainer>
        </div>
      </CNavbar>
    </VisibilitySensor>
  );
};

export default NavBar;
