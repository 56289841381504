import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import seta from '../../../assets/icon/seta-direita.png';

function AboutTwo() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <>
      <VisibilitySensor onChange={onChange} partialVisibility>
        <div className="sectionAboutThree">
          <div className={`item mission ${isVisible ? "visible" : ""}`}>
            <h2>Missão</h2>
            <p>
              Proteger nossos clientes, suas propriedades e ativos por meio de
              soluções de segurança de alta qualidade, garantindo tranquilidade
              e confiança.
            </p>
          </div>
          <div className={`item vision ${isVisible ? "visible" : ""}`}>
            <h2>Visão</h2>
            <p>
              Ser reconhecida como a principal escolha em segurança privada,
              oferecendo serviços inovadores e adaptáveis que superam as
              expectativas dos clientes.
            </p>
          </div>
          <div className={`item value ${isVisible ? "visible" : ""}`}>
            <h2>Valores</h2>
            <p><img src={seta} alt="" width={'25px'} />Integridade: Agir com honestidade, ética e transparência em
              todas as interações.</p>
            <p><img src={seta} alt="" width={'25px'} />Profissionalismo: Demonstrar competência,
              responsabilidade e respeito em todas as atividades.</p>
            <p><img src={seta} alt="" width={'25px'} />Segurança: Priorizar a segurança e o bem-estar dos
              clientes, funcionários e comunidade.</p>
            <p><img src={seta} alt="" width={'25px'} />Inovação: Buscar constantemente melhorias e soluções
              criativas para enfrentar desafios de segurança.</p>
            <p><img src={seta} alt="" width={'25px'} />Colaboração: Trabalhar em equipe e em parceria com
              clientes e autoridades para alcançar objetivos comuns.</p>
            <p><img src={seta} alt="" width={'25px'} />Compromisso: Cumprir com excelência todos os
              compromissos assumidos, garantindo a satisfação do cliente.</p>
            <p><img src={seta} alt="" width={'25px'} />Responsabilidade Social: Contribuir positivamente para
              a comunidade e o meio ambiente por meio de práticas sustentáveis e
              iniciativas sociais.</p>
          </div>
        </div>
      </VisibilitySensor>
    </>
  );
}

export default AboutTwo;
