import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { Link } from "react-router-dom";
import arrow from "../../../assets/icon/arrow.png";

import camera from "../../../assets/icon/services/camera.png";
import access from "../../../assets/icon/services/controle-de-acesso.png";
import access2 from "../../../assets/icon/services/portaria.png";
import concierge from "../../../assets/icon/services/porteiro.png";
import gardener from "../../../assets/icon/services/jardineiro.png";
import caretaker from "../../../assets/icon/services/zelador.png";
import vigilant2 from "../../../assets/icon/services/vigia.png";
import vigilant from "../../../assets/icon/services/vigilancia.png";
import recepcionist from "../../../assets/icon/services/recepcionista.png";
import fireman from "../../../assets/icon/services/bombeiro.png";
import services from "../../../assets/icon/services/servico.png";

function HomeServices() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <div>
      <VisibilitySensor onChange={onChange} partialVisibility>
        <div className={`services ${isVisible ? "visible" : ""}`}>
          <h1 className={`servicesTitle ${isVisible ? "visible" : ""}`}>
            Explore nossos serviços
          </h1>
          <div className="services-flex">
            <div className={`item ${isVisible ? "item1" : ""}`}>
              <img src={camera} alt="icone de monitoramento de alarmes" />
              <h2>Monitoramento de alarmes</h2>
              <p>
                O monitoramento de alarme consiste na supervisão constante de
                sistemas de segurança, como câmeras e sensores, por uma equipe
                especializada em uma central.
              </p>
            </div>
            <div className={`item ${isVisible ? "item2" : ""}`}>
              <img src={access} alt="icone de controle de acesso" />
              <h2>Controle de acesso</h2>
              <p>
                O controle de acesso regula a entrada e saída em áreas restritas
                por meio de métodos como cartões, biometria e reconhecimento
                facial.
              </p>
            </div>
            <div className={`item ${isVisible ? "item2" : ""}`}>
              <img src={access2} alt="icone de portaria autônoma" />
              <h2>Portaria Autônoma</h2>
              <p>
                A portaria autônoma utiliza tecnologias avançadas para gerenciar
                o acesso em edifícios sem a presença de porteiro.
              </p>
            </div>
            <div className={`item  ${isVisible ? "item3" : ""}`}>
              <img src={vigilant} alt="icone de vigilância" />
              <h2>Vigilância Física</h2>
              <p>
                Profissionais de segurança treinados atuam ativamente em
                situações de risco, protegendo pessoas e propriedades.
              </p>
            </div>
            <div className={`item  ${isVisible ? "item4" : ""}`}>
              <img src={concierge} alt="icone de porteiro" />
              <h2>Portaria</h2>
              <p>
                O serviço de portaria é vital para a segurança e ordem em
                ambientes como condomínios e empresas.
              </p>
            </div>
          </div>
          <div className="services-flex">
            <div className={`item  ${isVisible ? "item5" : ""}`}>
              <img src={vigilant2} alt="icone de vigia" />
              <h2>Vigia</h2>
              <p>
                O vigia monitora áreas específicas para manter a segurança e
                ordem do local. Sem autoridade para intervir, realiza rondas,
                observa e relata atividades suspeitas, contribuindo para a
                prevenção de crimes e a proteção de propriedades.
              </p>
            </div>
            <div className={`item  ${isVisible ? "item5" : ""}`}>
              <img src={services} alt="icone de auxiliar de serviços gerais" />
              <h2>Auxiliar de serviços gerais</h2>
              <p>
                Essenciais para manter a limpeza e organização em diferentes
                ambientes, os auxiliares realizam tarefas como limpeza, coleta
                de lixo, manutenção básica e prevenção de acidentes.
              </p>
            </div>
            <div className={`item  ${isVisible ? "item6" : ""}`}>
              <img src={gardener} alt="icone de jardineiro" />
              <h2>Jardineiro</h2>
              <p>
                Os jardineiros cuidam de jardins e áreas verdes, realizando
                tarefas como plantio, poda, irrigação, controle de pragas e
                seleção de plantas, para criar e manter espaços bonitos.
              </p>
            </div>
            <div className={`item  ${isVisible ? "item7" : ""}`}>
              <img src={caretaker} alt="icone de zelador" />
              <h2>Zelador</h2>
              <p>
                Os auxiliares de zeladoria são responsáveis pela manutenção
                eficaz de edifícios, realizando inspeções, limpeza, segurança,
                atendimento ao cliente.
              </p>
            </div>
          </div>
          <div className="services-flex">
            <div className={`item  ${isVisible ? "item8" : ""}`}>
              <img src={recepcionist} alt="icone de recepcionista" />
              <h2>Recepcionista</h2>
              <p>
                Fundamentais para a primeira impressão em empresas e
                organizações, os recepcionistas recebem e direcionam pessoas,
                atendem chamadas, agendam compromissos.
              </p>
            </div>
            <div className={`item  ${isVisible ? "item9" : ""}`}>
              <img src={fireman} alt="icone de bombeiro" />
              <h2>Bombeiro civil</h2>
              <p>
                Os bombeiros civis previnem e combatem incêndios, realizam
                salvamentos e prestam assistência em desastres.
              </p>
            </div>
          </div>
          <div className={`teste  ${isVisible ? "item8" : ""}`}>
            <div className="bord">
              <Link to="/services">
                <button className="button">
                  <h1>Ver mais</h1>
                  <img src={arrow} width={"20px"} alt="" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </div>
  );
}

export default HomeServices;
