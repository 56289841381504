import SocialMedia from "../../components/SocialMedia";
import NavBar from "../../components/NavBar";
import AboutOne from "../about/components/AboutOne";
import AboutTwo from "./components/AboutTwo";
/* import AboutThree from "./components/AboutThree"; */
import AboutFour from "./components/AboutFour";
import AboutFive from "./components/AboutFive";
import AboutSix from "./components/AboutSix";
import AboutSeven from "./components/AboutSeven";
import Footer from "../../components/Footer";

function About() {
  return (
    <div className="about">
      <SocialMedia />
      <NavBar />
      <AboutOne />
      {/* <AboutSix></AboutSix> */}
      <AboutSeven></AboutSeven>
      <AboutTwo />
      {/* <AboutThree/> */}
      <AboutFour />
      <AboutFive />
      <Footer />
    </div>
  );
}

export default About;
