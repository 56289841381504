import { React, useState } from "react";
import VisibilitySensor from "react-visibility-sensor";
import { useNavigate } from 'react-router-dom';

import link1 from "../../../assets/icon/midias/whatsapp.png";
import link2 from "../../../assets/img/budget/telefone.png";
import link3 from "../../../assets/img/budget/email.png";
import { api } from "../../../config/api";
import { useEmailSent } from "../../../context/EmailSentContext";

function BudgetOne() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [isEmailResponseLoading, setIsEmailResponseLoading] = useState(false);

  function phoneAuth(event) {
    const inputPhone = event.target.value;
    const formattedPhone = inputPhone
      .replace(/\D/g, "") // Remove todos os caracteres não numéricos
      .replace(/^(\d{2})(\d)/g, "($1) $2") // Adiciona parênteses em volta dos dois primeiros dígitos
      .replace(/(\d)(\d{4})$/, "$1-$2"); // Adiciona hífen entre o quinto e o sexto dígitos
    setPhone(formattedPhone);
  }

  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const { setShouldShowModal } = useEmailSent();

  const navigate = useNavigate();
  const sendEmail = async () => {
    try {
      setIsEmailResponseLoading(true);

      await api.post(`/sendEmail`, {
        name,
        email,
        phone,
        message,
        company: "Portovig",
      });

      setEmail("");
      setMessage("");
      setName("");
      setPhone("");
      navigate('/emailSuccess')
    } catch (error) {
      console.log(error);
    } finally {
      setIsEmailResponseLoading(false);
    }
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className={`budgetOne ${isVisible ? "visible" : ""}`}>
        <div className="budgetOneTitle">
          <h1 className={`${isVisible ? "visible" : ""}`}>
            Entre em contato conosco para obter um orçamento personalizado
          </h1>
          <p className={`${isVisible ? "visible" : ""}`}>
            Estamos ansiosos para receber sua mensagem e ajudá-lo da melhor
            forma possível.
          </p>
        </div>
        <form className="budgetForm" method="POST">
          <div id="form-item">
            <input
              id="nome"
              type="text"
              className="input"
              value={name}
              placeholder="Nome"
              autoComplete="off"
              maxLength={80}
              minLength={3}
              onChange={(event) => setName(event.target.value)}
            />
            <label className="label" htmlFor="nome"></label>
          </div>
          <div id="form-item">
            <input
              id="email"
              type="email"
              name="email"
              className="input"
              placeholder="E-mail"
              value={email}
              autoComplete="off"
              maxLength={60}
              onChange={(event) => setEmail(event.target.value)}
            />
            <label className="label" htmlFor="email"></label>
          </div>
          <div id="form-item">
            <input
              id="phone"
              type="phone"
              name="phone"
              className="input"
              placeholder="Telefone"
              value={phone}
              autoComplete="off"
              maxLength={15}
              minLength={15}
              onChange={phoneAuth}
            />
            <label className="label" htmlFor="phone"></label>
          </div>
          <div id="form-item">
            <input
              id="message"
              type="text"
              name="message"
              className="input input-message"
              placeholder="Mensagem"
              value={message}
              autoComplete="off"
              maxLength={300}
              minLength={25}
              as="text-area"
              onChange={(event) => setMessage(event.target.value)}
            />
            <label className="label" htmlFor="message"></label>
          </div>
          <input type="hidden" name="_captcha" value="false" />
          <input type="text" name="_honey" style={{ display: "none" }} />
          <button
            type="button"
            className="form-btn"
            disabled={isEmailResponseLoading}
            onClick={sendEmail}
          >
            {isEmailResponseLoading ? "Enviando..." : "Enviar"}
          </button>
        </form>
        <div className="budgetLinks">
          <div className="links">
            <div className={`phone item ${isVisible ? "visible" : ""}`}>
              <img src={link2} width={"20px"} alt="" />
              <p>+55 (47) 3348-9809</p>
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=5547997504443&text=Ol%C3%A1"
              target="_blank"
              rel="noreferrer"
            >
              <div className={`whats item ${isVisible ? "visible" : ""}`}>
                <img src={link1} width={"20px"} alt="" />
                <p>+55 (47) 99750-4443</p>
              </div>
            </a>
            <div className={`email item ${isVisible ? "visible" : ""}`}>
              <img src={link3} width={"20px"} alt="" />
              <p>contato@portovig.com.br</p>
            </div>
          </div>
          <div className={`underline ${isVisible ? "visible" : ""}`}></div>
        </div>
      </div>
    </VisibilitySensor>
  );
}

export default BudgetOne;
