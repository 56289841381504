import SocialMedia from "../../components/SocialMedia";
import Navbar from '../../components/NavBar';
import Footer from '../../components/Footer';
import ServicesOne from "./components/ServicesOne";
import ServicesTwo from './components/ServicesTwo';
import ServicesThree from './components/ServicesThree';
import ServicesFour from './components/ServicesFour';

function Services() {
  return (
    <div>
      <SocialMedia />
      <Navbar></Navbar>
      <ServicesOne></ServicesOne>
      <ServicesTwo></ServicesTwo>
      <div id="service3"><ServicesThree></ServicesThree></div>
      <div id="service4"><ServicesFour></ServicesFour></div>
      <Footer/>
    </div>
    );
}

export default Services;
