import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

function AboutFive() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  return (
    <>
      <VisibilitySensor onChange={onChange} partialVisibility>
        <div className="section-about-four">
          <div className={`section-four-title ${isVisible ? "visible" : ""}`}>
            <h1>Nossas políticas</h1>
          </div>
          <div className="section-four-content">
            <div className="item">
              <h2 className={`itemHOne ${isVisible ? "visible" : ""}`}>
                Política de Segurança
              </h2>
              <p className={`itemPOne ${isVisible ? "visible" : ""}`}>
                Garantimos que nossa empresa esteja em conformidade com todas as
                leis e regulamentações relevantes relacionadas à segurança
                privada, incluindo licenciamento, treinamento de funcionários e
                normas éticas de conduta. Esta política de segurança é uma parte
                essencial de nossa cultura organizacional e é incorporada em
                todas as nossas operações diárias. Todos os funcionários são
                responsáveis por seguir esta política e contribuir para um
                ambiente seguro e protegido.
              </p>
            </div>
            <div className="item ">
              <h2 className={`itemHTwo ${isVisible ? "visible" : ""}`}>
                Política de Qualidade
              </h2>
              <p className={`itemPTwo ${isVisible ? "visible" : ""}`}>
                A administração da Portovig está totalmente comprometida em
                garantir a qualidade de nossos serviços e em promover uma
                cultura de melhoria contínua em toda a organização. A
                administração fornece recursos e apoio necessários para
                implementar e manter esta política de qualidade. Esta política
                de qualidade é comunicada a todos os funcionários da Portovig e
                está disponível para todas as partes interessadas. Todos os
                funcionários são responsáveis por seguir esta política e
                contribuir para a realização de nossos objetivos de qualidade.
              </p>
            </div>
          </div>
        </div>
      </VisibilitySensor>
    </>
  );
}

export default AboutFive;
