import SocialMedia from "../../components/SocialMedia";
import NavBar from "../../components/NavBar";
import HomeHero from "./components/HomeHero";
import HomeAbout from "./components/HomeAbout";
import HomeServices from "./components/HomeServices";
import HomeMap from "./components/HomeMap";
import HomeFeed from "./components/HomeFeed";
import HomeCertifications from "./components/HomeCertifications";
import Footer from "../../components/Footer";

function Home() {
  return (
    <div>
      <SocialMedia />
      <NavBar />
      <div className="background-home">
        <HomeHero />
        <HomeAbout />
      </div>
      <HomeServices />
      <HomeMap />
      <HomeFeed/>
      <HomeCertifications/>
      <Footer />

    </div>
  );
}

export default Home;
