import React, { useState } from "react";
import VisibilitySensor from "react-visibility-sensor";

import { Swiper, SwiperSlide } from "swiper/react";
import { register } from "swiper/element/bundle";
import { EffectCoverflow } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-coverflow";
import nextArrow from "../../../assets/icon/next-arrow.png";
import prevArrow from "../../../assets/icon/prev-arrow.png";
import camera from "../../../assets/icon/services/camera.png";
import access from "../../../assets/icon/services/controle-de-acesso.png";
import concierge from "../../../assets/icon/services/porteiro.png";
import gardener from "../../../assets/icon/services/jardineiro.png";
import fireman from "../../../assets/icon/services/bombeiro.png";
import caretaker from "../../../assets/icon/services/zelador.png";
import vigilant from "../../../assets/icon/services/vigilancia.png";
import recepcionist from "../../../assets/icon/services/recepcionista.png";
import services from "../../../assets/icon/services/servico.png";

register();

function ServicesOne() {
  const [isVisible, setIsVisible] = useState(false);

  const onChange = (visibility) => {
    if (visibility) {
      setIsVisible(true);
    }
  };

  const data = [
    {
      id: 1,
      title: "Monitoramento de alarmes",
      content:
        "O serviço de monitoramento de sistema de segurança consiste na supervisão constante de sistemas eletrônicos de segurança, como câmeras de vigilância, alarmes e sensores, por uma equipe especializada. Essa equipe opera em uma central de monitoramento, onde recebem e analisam as informações geradas pelos dispositivos de segurança instalados em locais específicos, como residências, empresas, condomínios ou estabelecimentos comerciais.",
      imageUrl: camera,
    },
    {
      id: 2,
      title: "Controle de acesso",
      content:
        "O serviço de controle de acesso refere-se ao conjunto de procedimentos e tecnologias empregados para regular e monitorar a entrada e saída de pessoas, veículos ou informações em determinados locais ou sistemas. Esse serviço é essencial para garantir a segurança e a integridade de áreas restritas, como edifícios, instalações industriais, eventos, redes de computadores e outros ambientes sensíveis.",
      imageUrl: access,
    },
    {
      id: 3,
      title: "Bombeiro civil",
      content:
        "Bombeiros civis são especialistas em prevenir e combater incêndios, realizar resgates e oferecer ajuda em emergências. Suas funções incluem inspecionar equipamentos, treinar evacuações, prestar primeiros socorros, controlar incêndios e resgatar vítimas. Atuam em variados ambientes, desempenhando um papel essencial na proteção de vidas e propriedades, seguindo protocolos para garantir a segurança em situações de emergência.",
      imageUrl: fireman,
    },
    {
      id: 4,
      title: "Auxiliar de serviços gerais",
      content:
        "O serviço de Auxiliar de Serviços Gerais é fundamental para a manutenção e limpeza de ambientes comerciais, industriais, residenciais e institucionais. Os Auxiliares de Serviços Gerais desempenham uma variedade de tarefas essenciais para garantir que os espaços estejam limpos, organizados e seguros para os ocupantes.",
      imageUrl: services,
    },
    {
      id: 5,
      title: "Recepcionista",
      content:
        "O serviço de recepcionista é fundamental em diversos setores e organizações, desempenhando um papel crucial na primeira impressão que os clientes, visitantes ou pacientes têm ao entrar em um local. Os recepcionistas são responsáveis por receber, cumprimentar e direcionar pessoas de forma cortês e eficiente. ",
      imageUrl: recepcionist,
    },
    {
      id: 6,
      title: "Vigilância Física",
      content:
        "O serviço de vigilante, refere-se à atuação de profissionais de segurança treinados e autorizados para intervir ativamente em situações de risco ou emergência. Os vigilantes têm a responsabilidade de proteger pessoas, bens e propriedades contra ameaças imediatas, como intrusos, assaltantes ou outras formas de violência.",
      imageUrl: vigilant,
    },
    {
      id: 7,
      title: "Vigia",
      content:
        "O serviço de vigia, refere-se principalmente à monitorização de áreas específicas para garantir a segurança, ordem e integridade do local. Os vigias geralmente não têm autoridade para intervir em situações de conflito ou aplicar medidas de segurança, ao contrário dos vigilantes",
      imageUrl: vigilant,
    },
    {
      id: 8,
      title: "Jardineiro",
      content:
        "O serviço de jardineiro é dedicado ao cuidado e manutenção de jardins, áreas verdes e espaços paisagísticos. Os jardineiros são especialistas em horticultura e paisagismo, responsáveis por realizar uma variedade de tarefas para manter a beleza e a saúde de plantas, flores, árvores e gramados. ",
      imageUrl: gardener,
    },
    {
      id: 8,
      title: "Portaria autônoma",
      content:
        "O serviço de portaria autônoma refere-se a uma abordagem moderna e automatizada para o controle de acesso em edifícios residenciais, comerciais ou corporativos. Neste modelo, sistemas tecnológicos avançados são utilizados para gerenciar a entrada e saída de pessoas, veículos e entregas, sem a necessidade de um porteiro ou vigilante presencialmente. ",
      imageUrl: gardener,
    },
    {
      id: 8,
      title: "Zelador",
      content:
        "O serviço de Auxiliar de Zeladoria desempenha um papel importante na manutenção e operação eficaz de edifícios comerciais, residenciais e institucionais. Os Auxiliares de Zeladoria são responsáveis por uma variedade de tarefas essenciais que contribuem para a segurança, limpeza e funcionamento geral do ambiente em que trabalham.",
      imageUrl: caretaker,
    },
    {
      id: 9,
      title: "Portaria",
      content:
        "O serviço de portaria é essencial para garantir a segurança e a ordem em diversos ambientes, como condomínios, empresas, instituições educacionais e estabelecimentos comerciais. Os profissionais de portaria são responsáveis por controlar o acesso de pessoas e veículos, registrando a entrada e saída de visitantes, funcionários e prestadores de serviços.",
      imageUrl: concierge,
    },
  ];

  const breakpoints = {
    1000: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
    0: {
      slidesPerView: 1,
    },
  };

  return (
    <VisibilitySensor onChange={onChange} partialVisibility>
      <div className="servicesOne">
        <div className={`servicesOneTitle ${isVisible ? "visible" : ""}`}>
          <h1>
            Entenda como cada um de <br /> nossos serviços funciona
          </h1>
        </div>
        <Swiper
          className={`${isVisible ? "visible" : ""}`}
          modules={[EffectCoverflow]}
          effect="coverflow"
          spaceBetween={0}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          loop={true}
          speed={800}
          keyboard={true}
          breakpoints={breakpoints}
          slidesPerView={3}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          {data.map((item) => (
            <SwiperSlide key={item.id}>
              <div className="card">
                <img src={item.imageUrl} alt="" />
                <h1>{item.title}</h1>
                <p>{item.content}</p>
              </div>
            </SwiperSlide>
          ))}
          <div className="swiper-button-next">
            <img src={nextArrow} width={"35px"} alt="" />
          </div>
          <div className="swiper-button-prev">
            <img src={prevArrow} width={"35px"} alt="" />
          </div>
        </Swiper>
      </div>
    </VisibilitySensor>
  );
}

export default ServicesOne;
